/**
 * @module datetime
 * @description
 * This module provides utilities for handling dates and times based on the client's local settings.

 * **Important Notice:**
 * - **Do not use this module for backend values.**
 * - This module operates solely on the client's local `Date` object.
 * - It does not account for server-side date and time configurations or time zone differences.

 * @remarks
 * This module is intended for front-end applications where date and time 
 * manipulations are based on the user's local environment. 
 * Using it for backend operations may lead to inconsistencies due to differences in server and client time zones.
 * This module uses date-fns library for formatting needs, it can easily be replaced in the future.

 * @example
 * // Example usage:
 *
 * import { getYearNumber, getYearString, Timestamp, formattedDateDevided, formattedDateFull } from 'datetime';
 *
 * const year: number = getYearNumber();
 * console.log(year); // Output: 2023 (example)
 *
 * const formattedDateDevided: string = formattedDateUS();
 * console.log(formattedDateDevided); // Output: "09/30/2023" (example)
 *
 * const formattedDateFull: string = formattedDateFull();
 * console.log(formattedDateFull); // Output: "Sep 30, 2023" (example)
 *
 * const yearString: string = getYearString();
 * console.log(yearString); // Output: "2023" (example)
 * 
 * // It also can be used to get the current timestamp and use it later in all provided functions
 * const timestamp: Timestamp = getTimestamp();
 * const formattedDateDevided: string = formattedDateUS(timestamp);
 * console.log(formattedDateDevided); // Output: "09/30/2023" (example)
 */
import { format, endOfDay as internalEndOfDay, compareAsc } from "date-fns";

const DEFAULT_FORMAT = "yyyy-MM-dd HH:mm:ss";
const DEFAULT_FORMAT_DASHED = "yyyy-MM-dd";
const DEFAULT_FORMAT_US = "mm/dd/yyyy";
const DEFAULT_FORMAT_COMMA = "MMM dd, yyyy";
const DEFAULT_FORMAT_YEAR = "yyyy";
const DEFAULT_FORMAT_US_WITH_TIME = "MM/dd/yyyy, h:mm a";
const DEFAULT_FORMAT_DUE = "MMM do";
const DEFAULT_FORMAT_DUE_FULL = "MMM do, yyyy";
const GLOBAL_DATE_FORMAT = "MMM d, yyyy";

// Format a timestamp into a readable date string
const formatTimestamp = (date, dateFormat) => {
  try {
    const result = format(new Date(date), dateFormat);
    return result;
  } catch (error) {
    console.log(`Error formatting timestamp: ${error}`);
  }
};

// Get the current timestamp (milliseconds since the Unix epoch)

const getTimestamp = (date) => (date ? date.getDate() : Date.now());

export const getDate = (timestamp) =>
  timestamp ? new Date(timestamp) : new Date(getTimestamp());

export const endOfDay = (timestamp = getTimestamp()) =>
  internalEndOfDay(new Date(timestamp)).getTime();
export const dateCompareAsc = (left, right) =>
  compareAsc(new Date(left), new Date(right)) === 1;
export const dateCompareDesc = (left, right) =>
  compareAsc(new Date(left), new Date(right)) === -1;
// Default formatters
export const getYearNumber = (date = getDate()) => new Date(date).getFullYear();

export const getYearString = (date = getDate()) =>
  formatTimestamp(date, DEFAULT_FORMAT_YEAR);

export const formattedDateUS = (date = getDate()) =>
  formatTimestamp(date, DEFAULT_FORMAT_US);

export const formattedDateFull = (date = getDate()) =>
  formatTimestamp(date, DEFAULT_FORMAT_COMMA);

export const formattedDateUSWithTime = (date = getDate()) =>
  formatTimestamp(date, DEFAULT_FORMAT_US_WITH_TIME);

export const formattedDateDue = (date = getDate()) =>
  formatTimestamp(date, DEFAULT_FORMAT_DUE);

export const formattedDateDueFull = (date = getDate()) =>
  formatTimestamp(date, DEFAULT_FORMAT_DUE_FULL);
export const formattedDefault = (date = getDate()) =>
  formatTimestamp(date, DEFAULT_FORMAT);
export const formattedDashed = (date = getDate()) =>
  formatTimestamp(date, DEFAULT_FORMAT_DASHED);

export const calculateAge = (date = getDate()) => {
  const birthDate = new Date(date);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const month = today.getMonth() - birthDate.getMonth();
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
export const formattedGlobalDate = (date = getDate()) =>
  formatTimestamp(date, GLOBAL_DATE_FORMAT);
