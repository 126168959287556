import axios from 'axios';
import { addOrEditItem, deleteItem, getItems, API_ROOT, logApiError } from '@/store/api';

export default {
    namespaced: true,
    state: {
        onboardingInfo: {},
        onboardings: [],
        preSelectedSolarProject: null,
        savedSolarProjects: [],
        yourSolarProjects: [],
    },
    mutations: {
        updateOnboardings(state, onboardings) {
            state.onboardings = onboardings || [];
        },
        updatePreSelectedSolarProject(state, preSelectedSolarProject) {
            state.preSelectedSolarProject = preSelectedSolarProject;
        },
        updateSavedSolarProjects(state, savedSolarProjects) {
            state.savedSolarProjects = savedSolarProjects;
        },
        updateYourSolarProjects(state, yourSolarProjects) {
            state.yourSolarProjects = yourSolarProjects;
        },
    },
    getters: {
        onboardings: state => {
            return state.onboardings || [];
        },
        pre_selected_solar_project: state => {
            return state.preSelectedSolarProject || null;
        },
        saved_solar_projects: state => {
            return state.savedSolarProjects || [];
        },
        your_solar_projects: state => {
            return state.yourSolarProjects || [];
        },
    },
    actions: {
        getOnboardings: getItems('onboardings/'),
        deleteOnboarding: deleteItem('onboardings/'),
        createOnboarding: addOrEditItem('onboardings/'),
        updateOnboarding: addOrEditItem('onboardings/'),  // Similar to line above, naming simplification
        createOnboardingAdditionalDataItem: addOrEditItem( 'additional-data/', 'onboardings/'),
        createOnboardingDocumentItem: addOrEditItem('document/', 'onboardings/'),
        deleteOnboardingDocumentItem: deleteItem('document/', 'onboardings/', true),
        createOnboardingGeneratedDocumentItem: addOrEditItem('generated-document/', 'onboardings/'),
        deleteOnboardingGeneratedDocumentItem: deleteItem('generated-document/', 'onboardings/'),
        createOnboardingProfileDataItem: addOrEditItem('profile-data/', 'onboardings/'),
        createOnboardingRelationshipsDataItem: addOrEditItem('relationships-data/', 'onboardings/'),
        getOnboardingTrustAgreements: getItems('generated-trust-agreements/', 'onboardings/'),

        async postUserOnboardingEmail({ dispatch, rootGetters }, payload) {
            try {
                const user_id = payload.user_id;
                const onboarding_id = payload.onboarding_id;
                await axios.post(
                    `${API_ROOT}/users/${user_id}/onboardings/${onboarding_id}/send-email/`,
                    payload,
                    rootGetters.axiosConfig,
                );
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
    },
};
