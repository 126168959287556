import axios from 'axios';
import {
    CRUT_V1_API_ROOT,
    CRUT_V2_API_ROOT,
    CAPITAL_GAINS_COMPARISON_API_ROOT,
    ESTATE_COMPARISON_API_ROOT,
    GRAT_V2_API_ROOT,
    IRA_API_ROOT,
    OIL_GAS_API_ROOT,
    PPLI_API_ROOT,
    QSBS_API_ROOT,
    SOLAR_API_ROOT,
    logApiError,
} from './api';


export default {
    namespaced: true,
    state: {
        clatModelForm: {},
        crutReportData: {},
        crutModelForm: {},
        crutModelLength: 'max_length',
        crutModelStrategy: 'nimcrut',
        gratModelForm: {},
        iraModelForm: {},
        oilGasModelForm: {},
        ppliModelForm: {},
        qsbsModelForm: {},
        solarModelForm: {},
        totalOrdinaryIncome: null,
        totalLtcgIncome: null,
    },
    mutations: {
        updateClatModelForm(state, data) {
            state.clatModelForm = data;
        },
        updateCrutModelForm(state, data) {
            state.crutModelForm = data;
        },
        updateCrutModelLength(state, data) {
            state.crutModelLength = data;
        },
        updateCrutModelStrategy(state, data) {
            state.crutModelStrategy = data;
        },
        updateCrutReportData(state, data) {
            state.crutReportData = data;
        },
        updateGratModelForm(state, data) {
            state.gratModelForm = data;
        },
        updateIraModelForm(state, data) {
            state.iraModelForm = data;
        },
        updateOilGasModelForm(state, data) {
            state.oilGasModelForm = data;
        },
        updatePpliModelForm(state, data) {
            state.ppliModelForm = data;
        },
        updateQsbsModelForm(state, data) {
            state.qsbsModelForm = data;
        },
        updateSolarModelForm(state, data) {
            state.solarModelForm = data;
        },
        updateTotalOrdinaryIncome(state, data) {
            state.totalOrdinaryIncome = data;
        },
        updateTotalLtcgIncome(state, data) {
            state.totalLtcgIncome = data;
        },
    },
    getters: {
        clat_model_form: state => {
            return state.clatModelForm || {};
        },
        crut_report_data: state => {
            return state.crutReportData || {};
        },
        crut_model_form: state => {
            return state.crutModelForm || {};
        },
        crut_model_length: state => {
            return state.crutModelLength || '';
        },
        crut_model_strategy: state => {
            return state.crutModelStrategy || '';
        },
        grat_model_form: state => {
            return state.gratModelForm || {};
        },
        ira_model_form: state => {
            return state.iraModelForm || {};
        },
        oil_gas_model_form: state => {
            return state.oilGasModelForm || {};
        },
        ppli_model_form: state => {
            return state.ppliModelForm || {};
        },
        qsbs_model_form: state => {
            return state.qsbsModelForm || {};
        },
        solar_model_form: state => {
            return state.solarModelForm || {};
        },
        total_ordinary_income: state => {
            return state.totalOrdinaryIncome || 0;
        },
        total_ltcg_income: state => {
            return state.totalLtcgIncome || 0;
        },
    },
    actions: {
        async calculateClat({ dispatch }, payload) {
            try {
                const defaultPromise = axios.post(
                    `${CRUT_V1_API_ROOT}/calculate/default/clat`,
                    payload,
                );
                const trustPromise = axios.post(
                    `${CRUT_V1_API_ROOT}/calculate/clat`,
                    payload,
                );

                const defaultData = await defaultPromise;
                const trustData = await trustPromise;

                return {
                    defaultData: defaultData['data'],
                    trustData: trustData['data'],
                };
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async calculateCrut({ dispatch }, payload) {
            try {
                const defaultLifetimeNimcrutPromise = axios.post(
                    `${CRUT_V2_API_ROOT}/calculate/default/lifetime`,
                    { ...payload, compare_trust_type: 'NIMCRUT' },
                );
                const defaultMaxLengthNimcrutPromise = axios.post(
                    `${CRUT_V2_API_ROOT}/calculate/default/lifetime`,
                    { ...payload, compare_trust_type: 'NIMCRUT', is_max_length: true },
                );
                const defaultLifetimeScrutPromise = axios.post(
                    `${CRUT_V2_API_ROOT}/calculate/default/lifetime`,
                    { ...payload, compare_trust_type: 'SCRUT' },
                );
                const defaultMaxLengthScrutPromise = axios.post(
                    `${CRUT_V2_API_ROOT}/calculate/default/lifetime`,
                    { ...payload, compare_trust_type: 'SCRUT', is_max_length: true },
                );
                const defaultTermNimcrutPromise = axios.post(
                    `${CRUT_V2_API_ROOT}/calculate/default/term`,
                    { ...payload, compare_trust_type: 'NIMCRUT' },
                );
                const defaultTermScrutPromise = axios.post(
                    `${CRUT_V2_API_ROOT}/calculate/default/term`,
                    { ...payload, compare_trust_type: 'SCRUT' },
                );
                const scrutLifetimePromise = axios.post(
                    `${CRUT_V2_API_ROOT}/calculate/scrut/lifetime`,
                    payload,
                );
                const scrutMaxLengthPromise = axios.post(
                    `${CRUT_V2_API_ROOT}/calculate/scrut/lifetime`,
                    { ...payload, is_max_length: true },
                );
                const scrutTermPromise = axios.post(
                    `${CRUT_V2_API_ROOT}/calculate/scrut/term`,
                    payload,
                );
                const nimcrutLifetimePromise = axios.post(
                    `${CRUT_V2_API_ROOT}/calculate/nimcrut/lifetime`,
                    payload,
                );
                const nimcrutMaxLengthPromise = axios.post(
                    `${CRUT_V2_API_ROOT}/calculate/nimcrut/lifetime`,
                    { ...payload, is_max_length: true },
                );
                const nimcrutTermPromise = axios.post(
                    `${CRUT_V2_API_ROOT}/calculate/nimcrut/term`,
                    payload,
                );

                const defaultLifetimeNimcrutData = await defaultLifetimeNimcrutPromise;
                const defaultMaxLengthNimcrutData = await defaultMaxLengthNimcrutPromise;
                const defaultLifetimeScrutData = await defaultLifetimeScrutPromise;
                const defaultMaxLengthScrutData = await defaultMaxLengthScrutPromise;
                const defaultTermNimcrutData = await defaultTermNimcrutPromise;
                const defaultTermScrutData = await defaultTermScrutPromise;
                const scrutLifetimeData = await scrutLifetimePromise;
                const scrutMaxLengthData = await scrutMaxLengthPromise;
                const scrutTermData = await scrutTermPromise;
                const nimcrutLifetimeData = await nimcrutLifetimePromise;
                const nimcrutMaxLengthData = await nimcrutMaxLengthPromise;
                const nimcrutTermData = await nimcrutTermPromise;

                return {
                    defaultLifetimeNimcrutData: defaultLifetimeNimcrutData['data'],
                    defaultMaxLengthNimcrutData: defaultMaxLengthNimcrutData['data'],
                    defaultLifetimeScrutData: defaultLifetimeScrutData['data'],
                    defaultMaxLengthScrutData: defaultMaxLengthScrutData['data'],
                    defaultTermNimcrutData: defaultTermNimcrutData['data'],
                    defaultTermScrutData: defaultTermScrutData['data'],
                    scrutLifetimeData: scrutLifetimeData['data'],
                    scrutMaxLengthData: scrutMaxLengthData['data'],
                    scrutTermData: scrutTermData['data'],
                    nimcrutLifetimeData: nimcrutLifetimeData['data'],
                    nimcrutMaxLengthData: nimcrutMaxLengthData['data'],
                    nimcrutTermData: nimcrutTermData['data'],
                };
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async calculateIra({ dispatch }, payload) {
            try {
                const defaultLifetimePromise = axios.post(
                    `${IRA_API_ROOT}/calculate/default/lifetime`,
                    payload,
                );
                const defaultTermPromise = axios.post(
                    `${IRA_API_ROOT}/calculate/default/term`,
                    payload,
                );
                const standardCrutLifetimePromise = axios.post(
                    `${IRA_API_ROOT}/calculate/standard_crut/lifetime`,
                    payload,
                );
                const standardCrutTermPromise = axios.post(
                    `${IRA_API_ROOT}/calculate/standard_crut/term`,
                    payload,
                );
                const nimcrutLifetimePromise = axios.post(
                    `${IRA_API_ROOT}/calculate/nimcrut/lifetime`,
                    payload,
                );
                const nimcrutTermPromise = axios.post(
                    `${IRA_API_ROOT}/calculate/nimcrut/term`,
                    payload,
                );

                const defaultLifetimeData = await defaultLifetimePromise;
                const defaultTermData = await defaultTermPromise;
                const standardCrutLifetimeData = await standardCrutLifetimePromise;
                const standardCrutTermData = await standardCrutTermPromise;
                const nimcrutLifetimeData = await nimcrutLifetimePromise;
                const nimcrutTermData = await nimcrutTermPromise;

                return {
                    defaultLifetimeData: defaultLifetimeData['data'],
                    defaultTermData: defaultTermData['data'],
                    standardCrutLifetimeData: standardCrutLifetimeData['data'],
                    standardCrutTermData: standardCrutTermData['data'],
                    nimcrutLifetimeData: nimcrutLifetimeData['data'],
                    nimcrutTermData: nimcrutTermData['data'],
                };
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async calculateGrat({ dispatch }, payload) {
            try {
                const { data } = await axios.post(
                    `${GRAT_V2_API_ROOT}/calculate/`,
                    payload,
                );

                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async calculateOilGas({ dispatch }, payload) {
            try {
                const defaultPromise = axios.post(
                    `${OIL_GAS_API_ROOT}/calculate/default`,
                    payload,
                );
                const scenarioPromise = axios.post(
                    `${OIL_GAS_API_ROOT}/calculate/scenario`,
                    payload,
                );

                const defaultData = await defaultPromise;
                const scenarioData = await scenarioPromise;

                return {
                    'defaultData': defaultData['data'],
                    'scenarioData': scenarioData['data'],
                };
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async calculatePpli({ dispatch }, payload) {
            try {
                const estateTaxData = await axios.post(
                    `${PPLI_API_ROOT}/calculate/estate_tax_arrears/insurance`,
                    payload,
                );
                const scenarioData = await axios.post(
                    `${PPLI_API_ROOT}/calculate/scenario/insurance`,
                    payload,
                );

                return {
                    'estateTaxData': estateTaxData['data'],
                    'scenarioData': scenarioData['data'],
                };
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async calculateQsbs({ dispatch }, payload) {
            try {
                const defaultPromise = axios.post(
                    `${QSBS_API_ROOT}/calculate/default`,
                    payload,
                );
                const trustPromise = axios.post(
                    `${QSBS_API_ROOT}/calculate/trust`,
                    payload,
                );

                const defaultData = await defaultPromise;
                const trustData = await trustPromise;

                return {
                    defaultData: defaultData['data'],
                    trustData: trustData['data'],
                };
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async calculateSolarFlipPartnershipProject({ dispatch }, payload) {
            try {
                const { data } = await axios.post(
                    `${SOLAR_API_ROOT}/projects/flip_partnership/calculate`,
                    payload,
                );

                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async calculateSolarSaleLeasebackProject({ dispatch }, payload) {
            try {
                const { data } = await axios.post(
                    `${SOLAR_API_ROOT}/projects/sale_leaseback/calculate`,
                    payload,
                );

                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async calculateSolarScenario({ dispatch }, payload) {
            try {
                const { data } = await axios.post(
                    `${SOLAR_API_ROOT}/scenario/calculate`,
                    payload,
                );

                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async calculateSolarTaxSummary({ dispatch }, payload) {
            try {
                const { data } = await axios.post(
                    `${SOLAR_API_ROOT}/tax_benefits_summary/calculate`,
                    payload,
                );

                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async comparisonCalculatorCapitalGains({ dispatch }, payload) {
            try {
                const { data } = await axios.post(
                    `${CAPITAL_GAINS_COMPARISON_API_ROOT}/calculate`,
                    payload,
                );

                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async comparisonCalculatorEstate({ dispatch }, payload) {
            try {
                const { data } = await axios.post(
                    `${ESTATE_COMPARISON_API_ROOT}/calculate`,
                    payload,
                );

                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
    },
};
